<template>
  <div style="width: 40px;">
    <BAvatarGroup size="32px">
      <BAvatar
        v-if="snsChannel.includes('INSTA')"
        rounded
        variant="warning"
        class="pull-up"
      >
        <span>INS</span>
      </BAvatar>
      <BAvatar
        v-if="snsChannel.includes('BLOG')"
        rounded
        variant="success"
        class="pull-up"
      >
        <span>BLG</span>
      </BAvatar>
    </BAvatarGroup>
  </div>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BAvatarGroup,
  },
  props: {
    snsChannel: {
      type: String,
      required: true,
    },
  },
}
</script>
