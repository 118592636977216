<template>
  <div>
    <tagby-filter
      :category-options="categoryOptions"
      :initial-search-params="initialSearchParams"
      :lw="3"
      @search="search"
    >
      <template #buttons>
        <b-button
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'brand-sns-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">브랜드 SNS 추가</span>
        </b-button>
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        <b-link
          :to="{ name: 'brand-sns-detail', params: { idx: data.value } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template #cell(adv)="data">
        <div class="d-flex">
          <b-link
            :to="{ name: 'member-adv-view', params: { idx: data.item.adv.idx } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.adv.idx }}
          </b-link>
          <span>
            ({{ data.item.adv.name }})
          </span>
        </div>
      </template>

      <template #cell(pic)="data">
        <a
          :href="data.item.landing_url"
          target="_blank"
        >
          <BAvatar
            size="30"
            :src="data.item.profile_img_url"
          />
        </a>
      </template>

      <template #cell(service)="data">
        <SnsAvatar :snsChannel="data.value" />
      </template>

      <template #cell(created_at)="data">
        <div style="width: 90px">
          {{ resolveDatetime(data.item.created_at) }}
        </div>
      </template>
    </tagby-list>
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import SnsAvatar from './components/SnsAvatar.vue'

export default {
  components: {
    BLink,
    BButton,
    BAvatar,
    TagbyFilter,
    TagbyList,
    SnsAvatar,
  },
  setup() {
    const resolveDatetime = datetime => (moment(datetime).isValid() ? moment(datetime).format('YYYY-MM-DD HH:mm:ss') : null)
    return {
      resolveDatetime,
      ...viewModel(),
    }
  },
}
</script>
