import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchItems(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
