import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import storeModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-bca-list'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })
  const routerQuery = router.history.current.query
  const initialSearchParams = Object
    .keys(routerQuery)
    .reduce((previoutValue, currentValue) => {
      if (['page', 'size', 'total'].includes(currentValue)) {
        return previoutValue
      }
      if (currentValue === null || currentValue === '') {
        return previoutValue
      }
      if (typeof routerQuery[currentValue] === 'string') {
        return {
          ...previoutValue,
          [currentValue]: [routerQuery[currentValue]],
        }
      }
      return {
        ...previoutValue,
        [currentValue]: routerQuery[currentValue],
      }
    },
      {})
  const searchParams = ref({
    page: parseInt(routerQuery.page, 10) || 1,
    size: parseInt(routerQuery.size, 10) || 20,
    total: parseInt(routerQuery.total, 10) || 0,
    ...initialSearchParams,
  })

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => { })
  }

  const categoryOptions = [
    {
      label: '광고주 이름',
      value: 'adv_name',
      paramName: 'adv_name',
    },
    {
      label: 'adv_idx',
      value: 'adv_idx',
      paramName: 'adv_idx',
    },
    {
      label: 'state',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: 'ACTIVE', value: 'ACTIVE' },
        { text: 'RELOGIN', value: 'RELOGIN' },
        { text: 'WAIT', value: 'WAIT' },
        { text: 'INACTIVE', value: 'INACTIVE' },
      ],
    },
    {
      label: 'idx',
      paramName: 'idx',
    },
  ]

  const tableColumns = [
    { key: 'idx' },
    { key: 'adv', label: '사용자' },
    { key: 'service', label: 'SNS' },
    { key: 'pic' },
    { key: 'username' },
    { key: 'post_count', label: '포스트' },
    { key: 'follow_count', label: '팔로우' },
    { key: 'follower_count', label: '팔로워' },
    { key: 'state' },
    { key: 'created_at' },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const currentPage = computed(() => parseInt(searchParams.value.page, 10))
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchItems`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 가져오는데 실패했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const search = params => {
    Object.assign(searchParams.value, { ...params, page: 1 })
    fetchItems()
  }

  const changePage = params => {
    Object.assign(searchParams.value, params)
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })
  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,
  }
}
